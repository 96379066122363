import {
	CONFIRM_RESET_PASSWORD,
	INITIATE_RESET_PASSWORD,
	LOGIN,
	PASSWORD_REQUIREMENTS,
	TRUST_HUB,
} from '../../../services/api/api.path';
import { ISelectOption, RoleType } from '../../types';
import { getUserRoleDisplay } from '../common';

export const PAGE_SIZE = 50;
export const MAX_TAGS_DISPLAY_NUMBER = 3;
export const MIN_TAGS_DISPLAY_NUMBER = 2;
export const MAX_CONTROLS_DISPLAY_NUMBER = 5;
export const MIN_CONTROLS_DISPLAY_NUMBER = 2;
export const COMPANY_TIME_FORMAT = 'h:mm a';
export const ASSIGNMENT_ADD_DAYS = 365;

export const UnauthorizedRoutes = [
	PASSWORD_REQUIREMENTS,
	CONFIRM_RESET_PASSWORD,
	INITIATE_RESET_PASSWORD,
	LOGIN,
	TRUST_HUB,
];

export const AppQueryParams = {
	item: 'item',
	tab: 'tab',
	type: 'type',
	token: 'token',
};

export const SUPPORTED_TRUST_HUB_POLICY_TYPES = {
	'application/pdf': [],
};

export const SUPPORTED_TRUST_HUB_RESOURCES_TYPES = {
	'application/pdf': [],
};

export const SUPPORTED_POLICY_TYPES = {
	'application/pdf': [],
	'application/msword': [],
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
}; //["pdf", "doc", "docx"];

export const OnboardingStatus = {
	completed: 'completed',
	failed: 'failed',
	pending: 'pending',
	inProgress: 'in-progress',
};

export enum LayoutType {
	basic = 'basic',
	main = 'main',
}

export const baseLineOptions: ISelectOption[] = [
	{ value: 'low', label: 'Low' },
	{ value: 'moderate', label: 'Moderate' },
	{ value: 'high', label: 'High' },
];

export const AppTagColors = [
	'#A3E156',
	'#FFE453',
	'#93C2FD',
	'#FF8566',
	'#E298FD',
	'#2BDCFF',
	'#FFB856',
	'#2CF6F3',
	'#FFF1AA',
];

export const CommonColors = {
	skeletonBaseColor: '#f9f9ff',
	primaryFramework: '#FFB681',
	addNewTagBg: '#C9C6C6',
	defaultTagBg: '#EFEDFC',
	alertTag: '#FFF1AA',
	alertLightTag: '#FFFCEA',
	errorTag: '#FFC3B4',
	errorLightTag: '#FFF0ED',
};

export const StatusColors = {
	draft: '#CAE1FE',
	approved: '#D1F0AB',
	'pending-approval': '#FFDCAB',
	'pending-review': '#FFDCAB',
	'not-applicable': '#FFC3B4',
	archived: '#e7e7eb',
	reviewed: '#D1F0AB',
};

export const SensitivityColors = {
	high: '#FFDCAB',
	moderate: '#FFF1AA',
	low: '#D1F0AB',
	restricted: '#FFC3B4',
};

export enum AssetsSensitivity {
	Restricted = 'restricted',
	High = 'high',
	Moderate = 'moderate',
	Low = 'low',
}

export const AssetSensitivityDisplayName = {
	[AssetsSensitivity.High]: 'High',
	[AssetsSensitivity.Moderate]: 'Moderate',
	[AssetsSensitivity.Low]: 'Low',
	[AssetsSensitivity.Restricted]: 'Restricted',
};

export const AssetSensitivityOptions = [
	{
		value: AssetsSensitivity.High,
		label: AssetSensitivityDisplayName.high,
		metaData: { color: SensitivityColors.high },
	},
	{
		value: AssetsSensitivity.Moderate,
		label: AssetSensitivityDisplayName.moderate,
		metaData: { color: SensitivityColors.moderate },
	},
	{
		value: AssetsSensitivity.Low,
		label: AssetSensitivityDisplayName.low,
		metaData: { color: SensitivityColors.low },
	},
	{
		value: AssetsSensitivity.Restricted,
		label: AssetSensitivityDisplayName.restricted,
		metaData: { color: SensitivityColors.restricted },
	},
];

export enum AssetCriticality {
	MissionCritical = 'mission-critical',
	BusinessCritical = 'business-critical',
	BusinessOperational = 'business-operational',
	Administrative = 'administrative',
}

export const AssetCriticalityDisplayName = {
	[AssetCriticality.MissionCritical]: 'Mission-Critical',
	[AssetCriticality.BusinessCritical]: 'Business-Critical',
	[AssetCriticality.BusinessOperational]: 'Business Operational',
	[AssetCriticality.Administrative]: 'Administrative',
};

export const AssetCriticalityOptions = [
	{
		value: AssetCriticality.MissionCritical,
		label: AssetCriticalityDisplayName[AssetCriticality.MissionCritical],
	},
	{
		value: AssetCriticality.BusinessCritical,
		label: AssetCriticalityDisplayName[AssetCriticality.BusinessCritical],
	},
	{
		value: AssetCriticality.BusinessOperational,
		label: AssetCriticalityDisplayName[AssetCriticality.BusinessOperational],
	},
	{
		value: AssetCriticality.Administrative,
		label: AssetCriticalityDisplayName[AssetCriticality.Administrative],
	},
];

export enum AssetDisposition {
	OnPremisesSystem = 'on-premises-system',
	CloudEnvironment = 'cloud-environment',
	InTransit = 'in-transit',
	ServiceProvider = 'service-provider',
	PortableDevicesAndMedia = 'portable-devices-and-media',
}

export const AssetDispositionDisplayName = {
	[AssetDisposition.OnPremisesSystem]: 'On-Premises System',
	[AssetDisposition.CloudEnvironment]: 'Cloud Environment',
	[AssetDisposition.InTransit]: 'In Transit',
	[AssetDisposition.ServiceProvider]: 'Service Provider',
	[AssetDisposition.PortableDevicesAndMedia]: 'Portable Devices and Media',
};

export const AssetDispositionOptions = [
	{
		value: AssetDisposition.OnPremisesSystem,
		label: AssetDispositionDisplayName[AssetDisposition.OnPremisesSystem],
	},
	{
		value: AssetDisposition.CloudEnvironment,
		label: AssetDispositionDisplayName[AssetDisposition.CloudEnvironment],
	},
	{
		value: AssetDisposition.InTransit,
		label: AssetDispositionDisplayName[AssetDisposition.InTransit],
	},
	{
		value: AssetDisposition.ServiceProvider,
		label: AssetDispositionDisplayName[AssetDisposition.ServiceProvider],
	},
	{
		value: AssetDisposition.PortableDevicesAndMedia,
		label: AssetDispositionDisplayName[AssetDisposition.PortableDevicesAndMedia],
	},
];

export enum AssetsType {
	CRM = 'customer-relationship-management',
	ERP = 'enterprise-resource-planning',
	BI = 'business-intelligence',
	SCM = 'supply-chain-management',
	HCM = 'human-capital-management',
	PLM = 'product-lifecycle-management',
	MES = 'manufacturing-execution-system',
	EAM = 'enterprise-asset-management',

	//Infrastructure Components
	Servers = 'servers',
	StorageSolutions = 'storage-solutions',
	NetworkingEquipment = 'networking-equipment',
	Databases = 'databases',
	CloudEnvironments = 'cloud-environments',
	VirtualizationPlatforms = 'virtualization-platforms',
	Containers = 'containers',

	//Security and Compliance Solutions
	Firewalls = 'firewalls',
	IDS = 'intrusion-detection-systems',
	IPS = 'intrusion-prevention-systems',
	EPP = 'endpoint-protection-platforms',
	DLP = 'data-loss-prevention-solutions',
	IAM = 'identity-and-access-management-solutions',
	SecureWebGateways = 'secure-web-gateways',
	VPNS = 'virtual-private-networks',
	Encryption = 'encryption-solutions',
	HSMs = 'hardware-security-modules',
	SecureFileTransfer = 'secure-file-transfer-solutions',
	DataMasking = 'data-masking-tools',
	PKI = 'public-key-infrastructure',
	DataTokenization = 'data-tokenization-solutions',
	SIEM = 'security-information-and-event-management-systems',
	WAF = 'web-application-firewalls',
	MdmEmm = 'mobile-device-enterprise-mobility-management-solutions',
	IGA = 'identity-governance-and-administration',
	ThreatIntelligence = 'threat-intelligence-platforms',
	CASB = 'cloud-access-security-brokers',
	SASE = 'secure-access-service-edge',
	VaultingSecrets = 'vaulting-secrets-solutions',
	PAM = 'privileged-access-management',

	//Physical and Network Security
	PhysicalSecuritySystems = 'physical-security-systems',
	LoadBalancers = 'load-balancers',
	ApiGateways = 'api-gateways',
	DNS = 'domain-name-system-services',
	EmailSecuritySolutions = 'email-security-solutions',

	//Commonly Outsourced Services
	ThirdPartyDevelopment = 'third-party-development',
	ItSupportHelpdeskServices = 'it-support-and-helpdesk-services',
	CloudComputingServices = 'cloud-computing-services',
	CybersecurityServices = 'cybersecurity-services',
	DataCenterOperations = 'data-center-operations',
	HumanResourcesServices = 'human-resources-services',
	LegalServicesCompliance = 'legal-services-and-compliance',
	AccountingFinancialServices = 'accounting-and-financial-services',
	CustomerServiceCallCenters = 'customer-service-and-call-centers',
	DigitalMarketingSeoServices = 'digital-marketing-and-seo-services',
	ManufacturingProduction = 'manufacturing-and-production',
	LogisticsSupplyChainManagement = 'logistics-and-supply-chain-management',
	MarketResearchAnalysis = 'market-research-and-analysis',
	TrainingDevelopment = 'training-and-development',
}

export const AssetTypeDisplayName = {
	[AssetsType.CRM]: 'CRM (Customer Relationship Management)',
	[AssetsType.ERP]: 'ERP (Enterprise Resource Planning)',
	[AssetsType.BI]: 'BI (Business Intelligence)',
	[AssetsType.SCM]: 'SCM (Supply Chain Management)',
	[AssetsType.HCM]: 'HCM (Human Capital Management)',
	[AssetsType.PLM]: 'PLM (Product Lifecycle Management)',
	[AssetsType.MES]: 'MES (Manufacturing Execution System)',
	[AssetsType.EAM]: 'EAM (Enterprise Asset Management)',

	//Infrastructure Components
	[AssetsType.Servers]: 'Servers',
	[AssetsType.StorageSolutions]: 'Storage Solutions',
	[AssetsType.NetworkingEquipment]: 'Networking Equipment',
	[AssetsType.Databases]: 'Databases',
	[AssetsType.CloudEnvironments]: 'Cloud Environments',
	[AssetsType.VirtualizationPlatforms]: 'Virtualization Platforms',
	[AssetsType.Containers]: 'Containers',

	//Security and Compliance Solutions
	[AssetsType.Firewalls]: 'Firewalls',
	[AssetsType.IDS]: 'Intrusion Detection Systems (IDS)',
	[AssetsType.IPS]: 'Intrusion Prevention Systems (IPS)',
	[AssetsType.EPP]: 'Endpoint Protection Platforms (EPP)',
	[AssetsType.DLP]: 'Data Loss Prevention (DLP) Solutions',
	[AssetsType.IAM]: 'Identity and Access Management (IAM) Solutions',
	[AssetsType.SecureWebGateways]: 'Secure Web Gateways',
	[AssetsType.VPNS]: 'VPNs (Virtual Private Networks)',
	[AssetsType.Encryption]: 'Encryption Solutions',
	[AssetsType.HSMs]: 'Hardware Security Modules (HSMs)',
	[AssetsType.SecureFileTransfer]: 'Secure File Transfer Solutions',
	[AssetsType.DataMasking]: 'Data Masking Tools',
	[AssetsType.PKI]: 'Public Key Infrastructure (PKI)',
	[AssetsType.DataTokenization]: 'Data Tokenization Solutions',
	[AssetsType.SIEM]: 'Security Information and Event Management (SIEM) Systems',
	[AssetsType.WAF]: 'Web Application Firewalls (WAF)',
	[AssetsType.MdmEmm]:
		'Mobile Device Management (MDM) / Enterprise Mobility Management (EMM) Solutions',
	[AssetsType.IGA]: 'Identity Governance and Administration (IGA)',
	[AssetsType.ThreatIntelligence]: 'Threat Intelligence Platforms',
	[AssetsType.CASB]: 'Cloud Access Security Brokers (CASB)',
	[AssetsType.SASE]: 'SASE (Secure Access Service Edge)',
	[AssetsType.VaultingSecrets]: 'Vault Secrets Solutions',
	[AssetsType.PAM]: 'PAM (Privileged Access Management)',

	//Physical and Network Security
	[AssetsType.PhysicalSecuritySystems]: 'Physical Security Systems',
	[AssetsType.LoadBalancers]: 'Load Balancers',
	[AssetsType.ApiGateways]: 'API Gateways',
	[AssetsType.DNS]: 'DNS (Domain Name System) Services',
	[AssetsType.EmailSecuritySolutions]: 'Email Security Solutions',

	//Commonly Outsourced Services
	[AssetsType.ThirdPartyDevelopment]: 'Third-Party Development',
	[AssetsType.ItSupportHelpdeskServices]: 'IT Support and Helpdesk Services',
	[AssetsType.CloudComputingServices]: 'Cloud Computing Services',
	[AssetsType.CybersecurityServices]: 'Cybersecurity Services',
	[AssetsType.DataCenterOperations]: 'Data Center Operations',
	[AssetsType.HumanResourcesServices]: 'Human Resources (HR) Services',
	[AssetsType.LegalServicesCompliance]: 'Legal Services and Compliance',
	[AssetsType.AccountingFinancialServices]: 'Accounting and Financial Services',
	[AssetsType.CustomerServiceCallCenters]: 'Customer Service and Call Centers',
	[AssetsType.DigitalMarketingSeoServices]: 'Digital Marketing and SEO Services',
	[AssetsType.ManufacturingProduction]: 'Manufacturing and Production',
	[AssetsType.LogisticsSupplyChainManagement]: 'Logistics and Supply Chain Management',
	[AssetsType.MarketResearchAnalysis]: 'Market Research and Analysis',
	[AssetsType.TrainingDevelopment]: 'Training and Development',
};

export const AssetTypeOptions = [
	{
		value: AssetsType.CRM,
		label: AssetTypeDisplayName[AssetsType.CRM],
		groupName: 'Enterprise Systems',
	},
	{
		value: AssetsType.ERP,
		label: AssetTypeDisplayName[AssetsType.ERP],
		groupName: 'Enterprise Systems',
	},
	{
		value: AssetsType.BI,
		label: AssetTypeDisplayName[AssetsType.BI],
		groupName: 'Enterprise Systems',
	},
	{
		value: AssetsType.SCM,
		label: AssetTypeDisplayName[AssetsType.SCM],
		groupName: 'Enterprise Systems',
	},
	{
		value: AssetsType.HCM,
		label: AssetTypeDisplayName[AssetsType.HCM],
		groupName: 'Enterprise Systems',
	},
	{
		value: AssetsType.PLM,
		label: AssetTypeDisplayName[AssetsType.PLM],
		groupName: 'Enterprise Systems',
	},
	{
		value: AssetsType.MES,
		label: AssetTypeDisplayName[AssetsType.MES],
		groupName: 'Enterprise Systems',
	},
	{
		value: AssetsType.EAM,
		label: AssetTypeDisplayName[AssetsType.EAM],
		groupName: 'Enterprise Systems',
	},
	{
		value: AssetsType.Servers,
		label: AssetTypeDisplayName[AssetsType.Servers],
		groupName: 'Infrastructure Components',
	},
	{
		value: AssetsType.StorageSolutions,
		label: AssetTypeDisplayName[AssetsType.StorageSolutions],
		groupName: 'Infrastructure Components',
	},
	{
		value: AssetsType.NetworkingEquipment,
		label: AssetTypeDisplayName[AssetsType.NetworkingEquipment],
		groupName: 'Infrastructure Components',
	},
	{
		value: AssetsType.Databases,
		label: AssetTypeDisplayName[AssetsType.Databases],
		groupName: 'Infrastructure Components',
	},
	{
		value: AssetsType.CloudEnvironments,
		label: AssetTypeDisplayName[AssetsType.CloudEnvironments],
		groupName: 'Infrastructure Components',
	},
	{
		value: AssetsType.VirtualizationPlatforms,
		label: AssetTypeDisplayName[AssetsType.VirtualizationPlatforms],
		groupName: 'Infrastructure Components',
	},
	{
		value: AssetsType.Containers,
		label: AssetTypeDisplayName[AssetsType.Containers],
		groupName: 'Infrastructure Components',
	},
	{
		value: AssetsType.Firewalls,
		label: AssetTypeDisplayName[AssetsType.Firewalls],
		groupName: 'Security and Compliance Solutions',
	},
	{
		value: AssetsType.IDS,
		label: AssetTypeDisplayName[AssetsType.IDS],
		groupName: 'Security and Compliance Solutions',
	},
	{
		value: AssetsType.IPS,
		label: AssetTypeDisplayName[AssetsType.IPS],
		groupName: 'Security and Compliance Solutions',
	},
	{
		value: AssetsType.EPP,
		label: AssetTypeDisplayName[AssetsType.EPP],
		groupName: 'Security and Compliance Solutions',
	},
	{
		value: AssetsType.DLP,
		label: AssetTypeDisplayName[AssetsType.DLP],
		groupName: 'Security and Compliance Solutions',
	},
	{
		value: AssetsType.IAM,
		label: AssetTypeDisplayName[AssetsType.IAM],
		groupName: 'Security and Compliance Solutions',
	},
	{
		value: AssetsType.SecureWebGateways,
		label: AssetTypeDisplayName[AssetsType.SecureWebGateways],
		groupName: 'Security and Compliance Solutions',
	},
	{
		value: AssetsType.VPNS,
		label: AssetTypeDisplayName[AssetsType.VPNS],
		groupName: 'Security and Compliance Solutions',
	},
	{
		value: AssetsType.Encryption,
		label: AssetTypeDisplayName[AssetsType.Encryption],
		groupName: 'Security and Compliance Solutions',
	},
	{
		value: AssetsType.HSMs,
		label: AssetTypeDisplayName[AssetsType.HSMs],
		groupName: 'Security and Compliance Solutions',
	},
	{
		value: AssetsType.SecureFileTransfer,
		label: AssetTypeDisplayName[AssetsType.SecureFileTransfer],
		groupName: 'Security and Compliance Solutions',
	},
	{
		value: AssetsType.DataMasking,
		label: AssetTypeDisplayName[AssetsType.DataMasking],
		groupName: 'Security and Compliance Solutions',
	},
	{
		value: AssetsType.PKI,
		label: AssetTypeDisplayName[AssetsType.PKI],
		groupName: 'Security and Compliance Solutions',
	},
	{
		value: AssetsType.DataTokenization,
		label: AssetTypeDisplayName[AssetsType.DataTokenization],
		groupName: 'Security and Compliance Solutions',
	},
	{
		value: AssetsType.SIEM,
		label: AssetTypeDisplayName[AssetsType.SIEM],
		groupName: 'Security and Compliance Solutions',
	},
	{
		value: AssetsType.WAF,
		label: AssetTypeDisplayName[AssetsType.WAF],
		groupName: 'Security and Compliance Solutions',
	},
	{
		value: AssetsType.MdmEmm,
		label: AssetTypeDisplayName[AssetsType.MdmEmm],
		groupName: 'Security and Compliance Solutions',
	},
	{
		value: AssetsType.IGA,
		label: AssetTypeDisplayName[AssetsType.IGA],
		groupName: 'Security and Compliance Solutions',
	},
	{
		value: AssetsType.ThreatIntelligence,
		label: AssetTypeDisplayName[AssetsType.ThreatIntelligence],
		groupName: 'Security and Compliance Solutions',
	},
	{
		value: AssetsType.CASB,
		label: AssetTypeDisplayName[AssetsType.CASB],
		groupName: 'Security and Compliance Solutions',
	},
	{
		value: AssetsType.SASE,
		label: AssetTypeDisplayName[AssetsType.SASE],
		groupName: 'Security and Compliance Solutions',
	},
	{
		value: AssetsType.VaultingSecrets,
		label: AssetTypeDisplayName[AssetsType.VaultingSecrets],
		groupName: 'Security and Compliance Solutions',
	},
	{
		value: AssetsType.PAM,
		label: AssetTypeDisplayName[AssetsType.PAM],
		groupName: 'Security and Compliance Solutions',
	},
	{
		value: AssetsType.PhysicalSecuritySystems,
		label: AssetTypeDisplayName[AssetsType.PhysicalSecuritySystems],
		groupName: 'Physical and Network Security',
	},
	{
		value: AssetsType.LoadBalancers,
		label: AssetTypeDisplayName[AssetsType.LoadBalancers],
		groupName: 'Physical and Network Security',
	},
	{
		value: AssetsType.ApiGateways,
		label: AssetTypeDisplayName[AssetsType.ApiGateways],
		groupName: 'Physical and Network Security',
	},
	{
		value: AssetsType.DNS,
		label: AssetTypeDisplayName[AssetsType.DNS],
		groupName: 'Physical and Network Security',
	},
	{
		value: AssetsType.EmailSecuritySolutions,
		label: AssetTypeDisplayName[AssetsType.EmailSecuritySolutions],
		groupName: 'Physical and Network Security',
	},
	{
		value: AssetsType.ThirdPartyDevelopment,
		label: AssetTypeDisplayName[AssetsType.ThirdPartyDevelopment],
		groupName: 'Commonly Outsourced Services',
	},
	{
		value: AssetsType.ItSupportHelpdeskServices,
		label: AssetTypeDisplayName[AssetsType.ItSupportHelpdeskServices],
		groupName: 'Commonly Outsourced Services',
	},
	{
		value: AssetsType.CloudComputingServices,
		label: AssetTypeDisplayName[AssetsType.CloudComputingServices],
		groupName: 'Commonly Outsourced Services',
	},
	{
		value: AssetsType.CybersecurityServices,
		label: AssetTypeDisplayName[AssetsType.CybersecurityServices],
		groupName: 'Commonly Outsourced Services',
	},
	{
		value: AssetsType.DataCenterOperations,
		label: AssetTypeDisplayName[AssetsType.DataCenterOperations],
		groupName: 'Commonly Outsourced Services',
	},
	{
		value: AssetsType.HumanResourcesServices,
		label: AssetTypeDisplayName[AssetsType.HumanResourcesServices],
		groupName: 'Commonly Outsourced Services',
	},
	{
		value: AssetsType.LegalServicesCompliance,
		label: AssetTypeDisplayName[AssetsType.LegalServicesCompliance],
		groupName: 'Commonly Outsourced Services',
	},
	{
		value: AssetsType.AccountingFinancialServices,
		label: AssetTypeDisplayName[AssetsType.AccountingFinancialServices],
		groupName: 'Commonly Outsourced Services',
	},
	{
		value: AssetsType.CustomerServiceCallCenters,
		label: AssetTypeDisplayName[AssetsType.CustomerServiceCallCenters],
		groupName: 'Commonly Outsourced Services',
	},
	{
		value: AssetsType.DigitalMarketingSeoServices,
		label: AssetTypeDisplayName[AssetsType.DigitalMarketingSeoServices],
		groupName: 'Commonly Outsourced Services',
	},
	{
		value: AssetsType.ManufacturingProduction,
		label: AssetTypeDisplayName[AssetsType.ManufacturingProduction],
		groupName: 'Commonly Outsourced Services',
	},
	{
		value: AssetsType.LogisticsSupplyChainManagement,
		label: AssetTypeDisplayName[AssetsType.LogisticsSupplyChainManagement],
		groupName: 'Commonly Outsourced Services',
	},
	{
		value: AssetsType.MarketResearchAnalysis,
		label: AssetTypeDisplayName[AssetsType.MarketResearchAnalysis],
		groupName: 'Commonly Outsourced Services',
	},
	{
		value: AssetsType.TrainingDevelopment,
		label: AssetTypeDisplayName[AssetsType.TrainingDevelopment],
		groupName: 'Commonly Outsourced Services',
	},
];

export const CommonStatusesDisplayName = {
	draft: 'Draft',
	approved: 'Approved',
	'pending-approval': 'Pending',
	'pending-review': 'Pending Review',
	'not-applicable': 'Not Applicable',
	archived: 'Archived',
	reviewed: 'Reviewed',
};

export const CommonStatuses = {
	draft: 'draft',
	approved: 'approved',
	'pending-approval': 'pending-approval',
	'pending-review': 'pending-review',
	'not-applicable': 'not-applicable',
	archived: 'archived',
	reviewed: 'reviewed',
};

export const TrustHub = {
	published: 'published',
	'not-published': 'not-published',
};

export const TrustHubStatusOptions = [
	{ value: TrustHub.published, label: 'Published', metaData: { color: StatusColors.draft } },
	{
		value: TrustHub['not-published'],
		label: 'Not published',
		metaData: { color: StatusColors.approved },
	},
];

export const ControlStatusOptions = [
	{ value: CommonStatuses.draft, label: 'Draft', metaData: { color: StatusColors.draft } },
	{
		value: CommonStatuses.approved,
		label: 'Approved',
		metaData: { color: StatusColors.approved },
	},
	{
		value: CommonStatuses['pending-approval'],
		label: 'Pending',
		metaData: { color: StatusColors['pending-approval'] },
	},
	{
		value: CommonStatuses['not-applicable'],
		label: 'Not Applicable',
		metaData: { color: StatusColors['not-applicable'] },
	},
];

export const PolicyStatusOptions = [
	{
		value: CommonStatuses['pending-review'],
		label: 'Pending Review',
		metaData: { color: StatusColors['pending-review'] },
	},
	{
		value: CommonStatuses.reviewed,
		label: 'Reviewed',
		metaData: { color: StatusColors.approved },
	},
	{
		value: CommonStatuses.archived,
		label: 'Archived',
		metaData: { color: StatusColors['not-applicable'] },
	},
];

export const QueryParams = {
	token: 'token',
};

export const VariableUnitsDisplayName = {
	minutes: 'Minutes',
	hours: 'Hours',
	days: 'Days',
	months: 'Months',
	quaters: 'Quaters',
	years: 'Years',
};

export const VariableUnits = [
	{ value: 'minutes', label: VariableUnitsDisplayName.minutes },
	{ value: 'hours', label: VariableUnitsDisplayName.hours },
	{ value: 'days', label: VariableUnitsDisplayName.days },
	{ value: 'months', label: VariableUnitsDisplayName.months },
	{ value: 'quaters', label: VariableUnitsDisplayName.quaters },
	{ value: 'years', label: VariableUnitsDisplayName.years },
];

export enum Industry {
	InformationTechnology = 'information-technology',
	Tech = 'tech',
	Healthcare = 'healthcare',
	Education = 'education',
	Accounting = 'accounting',
	Pharmaceutical = 'pharmaceutical',
	Finance = 'finance',
	Engineering = 'engineering',
	RealEstate = 'real-estate',
	HigherEducation = 'higher-education',
	Sales = 'sales',
	Government = 'government',
	Energy = 'energy',
	Retail = 'retail',
	Manufacturing = 'manufacturing',
	Architecture = 'architecture',
	HumanResources = 'human-resources',
	Nonprofit = 'nonprofit',
	Transportation = 'transportation',
	Hospitality = 'hospitality',
}

export const IndustriesSelectOptions = [
	{
		value: Industry.InformationTechnology,
		label: 'Information Technology (IT)',
	},
	{
		value: Industry.Tech,
		label: 'Tech',
	},
	{
		value: Industry.Healthcare,
		label: 'Healthcare',
	},
	{
		value: Industry.Education,
		label: 'Education',
	},
	{
		value: Industry.Accounting,
		label: 'Accounting',
	},
	{
		value: Industry.Pharmaceutical,
		label: 'Pharmaceutical',
	},
	{
		value: Industry.Finance,
		label: 'Finance',
	},
	{
		value: Industry.Engineering,
		label: 'Engineering',
	},
	{
		value: Industry.RealEstate,
		label: 'Real Estate',
	},
	{
		value: Industry.HigherEducation,
		label: 'Higher Education',
	},
	{
		value: Industry.Sales,
		label: 'Sales',
	},
	{
		value: Industry.Government,
		label: 'Government',
	},
	{
		value: Industry.Energy,
		label: 'Energy',
	},
	{
		value: Industry.Retail,
		label: 'Retail',
	},
	{
		value: Industry.Manufacturing,
		label: 'Manufacturing',
	},
	{
		value: Industry.Architecture,
		label: 'Architecture',
	},
	{
		value: Industry.HumanResources,
		label: 'Human Resources',
	},
	{
		value: Industry.Nonprofit,
		label: 'Nonprofit',
	},
	{
		value: Industry.Transportation,
		label: 'Transportation',
	},
	{
		value: Industry.Hospitality,
		label: 'Hospitality',
	},
];

export const getRoleOptions = (companyName: string) => [
	{
		value: RoleType.clientAdmin,
		label: `${companyName} ${getUserRoleDisplay(RoleType.clientAdmin)}`,
	},
	{
		value: RoleType.shareholder,
		label: getUserRoleDisplay(RoleType.shareholder),
	},
];

export const VariableTypes = {
	numeric: 'Numeric',
	text: 'Text',
	time: 'Time',
};

export enum HistoryVariableType {
	Name = 'name',
	AssetName = 'asset-name',
	AssetDetails = 'asset-details',
	AssetType = 'asset-type',
	AssetDisposition = 'asset-disposition',
	AssetCriticality = 'asset-criticality',
	AssetSensitivity = 'asset-sensitivity',
	PolicyName = 'policy-name',
	PolicyStatus = 'policy-status',
	PolicyPreamble = 'policy-preamble',
	PolicyReviewPeriod = 'policy-review-period',
	Control = 'control',
	Status = 'status',
	Description = 'description',
	Owner = 'owner',
	Statement = 'statement',
	VariableValue = 'variable-value',
	VariableUnit = 'variable-unit',
}

export enum UserTaskType {
	AssetOwner = 'asset-owner',
	PolicyOwner = 'policy-owner',
	ControlOwner = 'control-owner',
	PolicyReview = 'policy-review',
}

export const UserTaskTypeDisplayName = {
	[UserTaskType.AssetOwner]: 'Asset Owner',
	[UserTaskType.PolicyOwner]: 'Policy Owner',
	[UserTaskType.ControlOwner]: 'Control Owner',
	[UserTaskType.PolicyReview]: 'Policy Review',
};

export enum TrustHubAccessRequestType {
	fullAccess = 'full-access',
	individualDocument = 'individual-document',
}

export const TrustHubAccessRequestTypeDisplayName = {
	[TrustHubAccessRequestType.fullAccess]: 'Full Access',
	[TrustHubAccessRequestType.individualDocument]: 'Individual Document',
} as const;
