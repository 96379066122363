import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './EditControl.module.scss';
import { IEditControl } from './IEditControl';
import { CommonColors, ControlStatusOptions } from '../../../../utils/helpers/constants';
import { EntityTypes, ICapturedValues } from '../../../../utils/types';
import { ActionsBlock, Button } from '../../../primitives';
import ModalLanguageQuidance from '../../modals/modal-language-quidance/ModalLanguageQuidance';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../../services/store';
import {
	IControl,
	IUpdateControl,
	addCommentToControl,
	getControlById,
	reapproveControl,
	updateControl,
} from '../../../../services/store/slices/controls.slice';
import { handleWithTryCatch } from '../../../../utils/helpers/errors';
import CommentsBlock from '../../comments-block/CommentsBlock';
import ChangesHistoryBlock from '../../changes-history-block/ChangesHistoryBlock';
import VariablesBlock from '../../variables-block/VariablesBlock';
import {
	Input,
	Select,
	SectionTitle,
	ColoredTag,
	CollapsiblePanel,
	Drawer,
	Scrollbar,
	Loader,
	Error,
} from '../../../primitives';
import {
	getMissedVariablesNumber,
	getOwnerOptions,
	sanitizeData,
	tagsAreDifferent,
	variablesAreDifferent,
} from '../../../../utils/helpers/common';
import EditControlSkeleton from './EditControlSkeleton';
import ModalLeaveWarning from '../../modals/modal-leave-warning/ModalLeaveWarning';
import { useLeaveWarning, useCompanyUsers, useControls, useUser } from '../../../../utils/helpers/hooks';
import TagsBlock from '../../tags-block/TagsBlock';
import ControlStatementBlock from '../../control-statement-block/ControlStatementBlock';
import { ITagBrief } from '../../../../services/store/slices/tags.slice';

const EditControl: FC<IEditControl> = ({
	controlId,
	onUpdateError,
	onUpdateSuccess,
	closeHandler,
	open,
}) => {
	const dispatch = useAppDispatch();
	const { isAdmin, info: userInfo } = useUser();
	const { items: companyUsers } = useCompanyUsers();
	const { controlLoading, commentsLoading, updateLoading, items: controlItems } = useControls();
	const { handleSubmit } = useForm();
	const [commentIsAdding, setCommentIsAdding] = useState(false);

	const [currentControl, setCurrentControl] = useState<IControl | null>();
	const {
		id,
		controlId: displayControlId,
		name,
		statement,
		status,
		owner,
		guidance,
		tags,
		capturedValues,
		comments,
		history,
	} = currentControl || {};

	const [currentFormState, setCurrentFormState] = useState<IUpdateControl | null>();
	const [openModal, setOpenModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [warningModalOpen, setWarningModalOpen] = useState(false);
	const [showBrowserLeaveWarning, setBrowserShowLeaveWarning] = useState(false);

	const [error, setError] = useState('');

	const initialEditFormState = useMemo(
		() => ({
			id,
			name,
			statement,
			status,
			ownerId: owner?.id || 'unassigned',
			tags,
			capturedValues,
		}),
		[id, name, owner?.id, statement, status, tags, capturedValues],
	);

	const ownersOptions = useMemo(
		() => getOwnerOptions(companyUsers, userInfo?.id),
		[companyUsers, userInfo?.id],
	);

	const missedStatement = useMemo(
		() => !currentFormState?.statement,
		[currentFormState?.statement],
	);

	const onFormSubmitHandler = () => {
		const getOwner = () => {
			if (!isAdmin) return userInfo?.id;

			return currentFormState?.ownerId === 'unassigned' ? null : currentFormState?.ownerId;
		};
		const sanitizedData = sanitizeData(currentFormState);

		const transformedCapturedValues = () => {
			if (!sanitizedData.capturedValues) return;

			const result = {};

			Object.entries(sanitizedData.capturedValues.values).forEach(([key, valuePair]) => {
				//@ts-ignore
				if (valuePair && valuePair.value)
					//@ts-ignore
					result[key] = valuePair.value;
			});

			return result;
		};

		if (id) {
			handleWithTryCatch(
				async () => {
					const dataToUpdate = {
						...sanitizedData,
						ownerId: getOwner(),
						...(sanitizedData.capturedValues && {
							capturedValues: transformedCapturedValues(),
						}),
						tags: sanitizedData.tags?.map((tag: ITagBrief) => tag.id),
					};

					await dispatch(updateControl(id, dataToUpdate));
					onUpdateSuccess();
				},
				undefined,
				onUpdateError,
			);
		}
	};

	const showReapproveButton = useMemo(() => {
		if (userInfo?.id === currentControl?.owner?.id && currentControl?.status === 'approved') return true;
		return false;
	}, [currentControl, userInfo])

	const onReapproveHandler = () => {
		const getOwner = () => {
			if (!isAdmin) return userInfo?.id;

			return currentFormState?.ownerId === 'unassigned' ? null : currentFormState?.ownerId;
		};
		const sanitizedData = sanitizeData(currentFormState);

		const transformedCapturedValues = () => {
			if (!sanitizedData.capturedValues) return;

			const result = {};

			Object.entries(sanitizedData.capturedValues.values).forEach(([key, valuePair]) => {
				//@ts-ignore
				if (valuePair && valuePair.value)
					//@ts-ignore
					result[key] = valuePair.value;
			});

			return result;
		};

		if (id) {
			handleWithTryCatch(
				async () => {
					const dataToUpdate = {
						...sanitizedData,
						ownerId: getOwner(),
						...(sanitizedData.capturedValues && {
							capturedValues: transformedCapturedValues(),
						}),
						tags: sanitizedData.tags?.map((tag: ITagBrief) => tag.id),
					};

					await dispatch(reapproveControl(id, dataToUpdate));
					onUpdateSuccess();
				},
				undefined,
				onUpdateError,
			);
		}
	};

	const renderVariables = () => {
		const missedVariablesNumber = getMissedVariablesNumber(currentFormState?.capturedValues);

		const trigger = (
			<SectionTitle
				className={styles['collapsible-trigger']}
				withTag={
					missedVariablesNumber > 0 && (
						<ColoredTag
							small
							tagId={'action'}
							text={`${missedVariablesNumber} Action Item${missedVariablesNumber > 1 ? 's' : ''}`}
							bgColor={CommonColors.alertTag}
						/>
					)
				}
			>
				Captured Values
			</SectionTitle>
		);

		const onVariablesChange = (variableValues: ICapturedValues) => {
			setCurrentFormState((prev) => ({ ...prev, capturedValues: variableValues }));
		};

		return (
			<CollapsiblePanel className={styles.variables} trigger={trigger}>
				<VariablesBlock
					onValuesChange={onVariablesChange}
					variables={currentFormState?.capturedValues!}
				/>
			</CollapsiblePanel>
		);
	};

	const renderTags = () => {
		const trigger = <SectionTitle className={styles['collapsible-trigger']}>Tags</SectionTitle>;

		return (
			<CollapsiblePanel trigger={trigger}>
				<TagsBlock
					entityTagIds={currentFormState?.tags || []}
					setEntityTagIds={(newTags) => {
						setCurrentFormState((prev) => ({
							...prev,
							tags: newTags,
						}));
					}}
				/>
			</CollapsiblePanel>
		);
	};

	const renderChangesHistory = () => {
		const trigger = (
			<SectionTitle className={styles['collapsible-trigger']}>History</SectionTitle>
		);

		return (
			<CollapsiblePanel trigger={trigger}>
				<ChangesHistoryBlock changes={history} />
			</CollapsiblePanel>
		);
	};

	const onCommentAdded = useCallback(() => {
		setCommentIsAdding(true);

		handleWithTryCatch(
			async () => {
				const control = await dispatch(getControlById(currentControl?.id || ''));
				const newComments = control?.comments;
				setCurrentControl((prev: IControl | null | undefined) => ({
					...prev!,
					comments: newComments,
				}));

				setCommentIsAdding(false);
			},
			setError,
			() => setCommentIsAdding(false),
		);
	}, [currentControl?.id, dispatch]);

	const renderComments = () => {
		const trigger = (
			<SectionTitle className={styles['collapsible-trigger']}>Comments</SectionTitle>
		);

		return (
			<CollapsiblePanel trigger={trigger}>
				<CommentsBlock
					entityId={id}
					entityType={EntityTypes.CompanyControl}
					comments={comments}
					addDispatchHandler={addCommentToControl}
					commentsLoading={commentsLoading || commentIsAdding}
					onAddSuccess={onCommentAdded}
				/>
			</CollapsiblePanel>
		);
	};

	const onCloseHandler = () => {
		if (changesWereMade) setWarningModalOpen(true);
		else closeHandler();
	};

	const renderActions = () => {
		return (
			<ActionsBlock className={styles.actions}>
				<Button width={168} type="button" negative onClick={onCloseHandler}>
					Cancel
				</Button>

				<div className='flex gap-4'>
					{showReapproveButton && (
						<Button width={168} type="button" disabled={controlLoading || updateLoading} onClick={onReapproveHandler}>
							{controlLoading || updateLoading ? (
								<Loader thin maxHeight={14} maxWidth={14} />
							) : (
								'Reapprove'
							)}
						</Button>
					)}
					<Button width={168} type="submit" disabled={controlLoading || updateLoading}>
						{controlLoading || updateLoading ? (
							<Loader thin maxHeight={14} maxWidth={14} />
						) : (
							'Save'
						)}
					</Button>
				</div>
			</ActionsBlock>
		);
	};

	const renderDetails = () => {
		const trigger = <SectionTitle className={styles.details}>Details</SectionTitle>;

		return (
			<CollapsiblePanel trigger={trigger}>
				<Input
					disabled
					className={styles.input}
					type="text"
					value={displayControlId}
					label="Control ID"
				/>

				<Input
					disabled
					type="text"
					className={styles.input}
					value={name}
					label="Control Name"
				/>

				<div className={styles['input-group']}>
					<Select
						id="status"
						onValueChange={(value) =>
							setCurrentFormState((prev) => ({ ...prev, status: value }))
						}
						defaultValue={currentFormState?.status}
						className={styles.input}
						options={ControlStatusOptions}
						label="Status"
					/>

					<Select
						id="owner"
						disabled={!isAdmin}
						onValueChange={(value) => {
							if (!isAdmin) return;
							setCurrentFormState((prev) => ({ ...prev, ownerId: value }));
						}}
						defaultValue={currentFormState?.ownerId}
						className={styles.input}
						label="Owner"
						options={ownersOptions || []}
					/>
				</div>
			</CollapsiblePanel>
		);
	};

	const renderStatement = () => {
		const trigger = (
			<SectionTitle
				className={styles.details}
				withTag={
					missedStatement && (
						<ColoredTag
							tagId="action"
							small
							text={'1 Action Item'}
							bgColor={CommonColors.alertTag}
						/>
					)
				}
			>
				Control Statement
			</SectionTitle>
		);

		return (
			<CollapsiblePanel trigger={trigger}>
				<ControlStatementBlock
					statement={currentFormState?.statement || ''}
					onStatementChange={(value) =>
						setCurrentFormState((prev) => ({
							...prev,
							statement: value,
						}))
					}
					setOpenModal={setOpenModal}
				/>
			</CollapsiblePanel>
		);
	};

	const editForm = (
		<form onSubmit={handleSubmit(onFormSubmitHandler)}>
			<Scrollbar className={styles.scrollbar}>
				<div className={styles.inputs}>
					{renderDetails()}

					{renderStatement()}

					{capturedValues && Object.keys(capturedValues).length
						? renderVariables()
						: null}

					{renderTags()}

					{renderComments()}

					{renderChangesHistory()}
				</div>
			</Scrollbar>

			{renderActions()}
		</form>
	);

	const changesWereMade = useMemo(() => {
		if (currentFormState?.id && initialEditFormState?.id) {
			const tagsDiffer = tagsAreDifferent(currentFormState?.tags, initialEditFormState?.tags);

			const variablesDiffer = variablesAreDifferent(
				initialEditFormState?.capturedValues,
				currentFormState?.capturedValues,
			);

			return !!(
				initialEditFormState.name !== currentFormState?.name ||
				initialEditFormState.statement !== currentFormState?.statement ||
				initialEditFormState.status !== currentFormState?.status ||
				initialEditFormState.ownerId !== currentFormState?.ownerId ||
				tagsDiffer ||
				variablesDiffer
			);
		}
		return false;
	}, [
		currentFormState?.capturedValues,
		currentFormState?.id,
		currentFormState?.name,
		currentFormState?.ownerId,
		currentFormState?.statement,
		currentFormState?.status,
		currentFormState?.tags,
		initialEditFormState?.capturedValues,
		initialEditFormState?.id,
		initialEditFormState.name,
		initialEditFormState.ownerId,
		initialEditFormState.statement,
		initialEditFormState.status,
		initialEditFormState?.tags,
	]);

	const modals = (
		<>
			<ModalLanguageQuidance open={openModal} setOpen={setOpenModal} guidance={guidance} />

			<ModalLeaveWarning
				open={warningModalOpen}
				setOpen={setWarningModalOpen}
				onConfirm={() => {
					setBrowserShowLeaveWarning(false);
					closeHandler();
				}}
			/>
		</>
	);

	useLeaveWarning(showBrowserLeaveWarning);

	useEffect(() => {
		setCurrentFormState(initialEditFormState);
	}, [initialEditFormState]);

	useEffect(() => {
		if (changesWereMade) setBrowserShowLeaveWarning(true);
	}, [changesWereMade]);

	useEffect(() => {
		if (error) setLoading(false);
	}, [error]);

	useEffect(() => {
		if (!currentControl && controlId) setLoading(true);
	}, [currentControl, controlId]);

	useEffect(() => {
		if (controlId && !currentControl && !loading && !error) {
			handleWithTryCatch(async () => {
				const control = await dispatch(getControlById(controlId));
				setCurrentControl(control);
				setLoading(false);
			}, setError);
		}
	}, [controlId, controlItems, currentControl, dispatch, error, loading]);

	useEffect(() => {
		if (!open) {
			setCurrentControl(null);
			setBrowserShowLeaveWarning(false);
			setError('');
		}
	}, [open]);

	return (
		<Drawer
			contentClassName={styles.drawer}
			title="Control Details"
			open={open}
			onCloseClickHandler={onCloseHandler}
		>
			{error ? <Error message={error} /> : null}

			{loading ? (
				<EditControlSkeleton />
			) : (
				<div className={styles['edit-control']}>
					{id ? editForm : null}

					{modals}
				</div>
			)}
		</Drawer>
	);
};

export default EditControl;
