import axios from 'axios';
import { BASE_POLICY, COMMENTS, FILES, COMPANY_POLICIES } from './api.path';
import { IComment, ICreateComment } from '../../utils/types';
import { IPolicyBasic, IBasePolicyBasic, IUpdatePolicy } from '../store/slices/policies.slice';

const policiesApi = {
	getPolicies: (): Promise<IPolicyBasic[]> => {
		return axios.get(`${COMPANY_POLICIES}`);
	},
	getBasePolicies: (companyPrimaryFramework: string): Promise<IBasePolicyBasic[]> => {
		return axios.get(`${BASE_POLICY}/all-published?frameworkId=${companyPrimaryFramework}`);
	},
	getArchivedPolicies: (): Promise<IPolicyBasic[]> => {
		return axios.get(`${COMPANY_POLICIES}?status=archived`);
	},
	getPolicyById: (id: string): Promise<IPolicyBasic> => {
		return axios.get(`${COMPANY_POLICIES}/${id}`);
	},
	updatePolicy: (policyId: string, data: IUpdatePolicy): Promise<IPolicyBasic> => {
		const owner = data.ownerId === null ? data.ownerId : Number(data.ownerId);

		return axios.put(`${COMPANY_POLICIES}/${policyId}`, {...data, ownerId: owner});
	},
	reapprovePolicy: (policyId: string, data: IUpdatePolicy): Promise<IPolicyBasic> => {
		const owner = data.ownerId === null ? data.ownerId : Number(data.ownerId);

		return axios.put(`${COMPANY_POLICIES}/${policyId}/approve`, {...data, ownerId: owner});
	},
	updatePolicyStatus: (policyId: string, status: string): Promise<IPolicyBasic> => {
		return axios.put(`${COMPANY_POLICIES}/status/${policyId}`, { status });
	},
	addBasePolicyToCompany: (basePolicyId: string): Promise<IPolicyBasic> => {
		return axios.post(`${COMPANY_POLICIES}/add-base-policy/${basePolicyId}`);
	},
	assignPolicies: (policyIds: string[], ownerId: string | null): Promise<IPolicyBasic> => {
		const owner = ownerId === null ? ownerId : Number(ownerId);

		return axios.put(`${COMPANY_POLICIES}/owner/bulk-update`, { policyIds, ownerId: owner });
	},
	addComment: (newComment: ICreateComment): Promise<IComment> => {
		return axios.post(`${COMMENTS}`, { ...newComment, entityId: Number(newComment.entityId) });
	},
	deletePolicyFile: (fileId: string) => {
		return axios.delete(`${FILES}/${fileId}`);
	},
	downloadPolicyFile: (policyId: string) => {
		return axios.get(`${COMPANY_POLICIES}/policy-document/${policyId}`);
	},};

export default policiesApi;
