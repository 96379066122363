import { Dispatch } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommonResponseError } from '../../../utils/helpers/errors/CommonResponseError';
import { IChangeHistoryEntry, IComment, ICreateComment, IIdName } from '../../../utils/types';
import { RootState } from '..';
import { sortByNameAsc } from '../../../utils/helpers/common';
import { ICompanyUser } from './company-users.slice';
import assetsApi from '../../api/assets.api';
import { ITag, ITagBrief } from './tags.slice';

export interface IAssetsSlice {
	loading?: boolean;
	assetLoading?: boolean;
	archivationLoading?: boolean;
	commentsLoading?: boolean;
}

export interface IAssetBasic {
	id: number;
	name: string;
	description?: string;
	details?: string;
	ownerId?: string | null;
	type?: string;
	disposition?: string;
	criticality?: string;
	sensitivity?: string;
	history?: IChangeHistoryEntry[];
	tags?: Partial<ITag>[];
	companyControlIds?: number[];
	comments?: IComment[];
}

export interface IAsset extends Omit<IAssetBasic, 'owner' | 'id' | 'companyControlIds' | 'tags'> {
	id: string;
	companyControls?: IIdName[];
	owner?: ICompanyUser | null;
	tags?: ITagBrief[];
}

export interface IAssets extends IAssetsSlice {
	items: IAsset[] | null;
	archivedItems: IAsset[] | null;
}

export interface ICreateAsset {
	name: string;
	type: string;
	description: string;
	disposition: string;
	sensitivity: string;
	criticality: string;
	ownerId: string;
	companyControls: IIdName[];
}

export interface IUpdateAsset extends Partial<ICreateAsset> {
	id?: string;
	details?: string;
	tags?: ITagBrief[];
}

const initialState: IAssets = {
	loading: false,
	assetLoading: false,
	archivationLoading: false,
	items: null,
	archivedItems: null,
	commentsLoading: false,
};

export const assetsSlice = createSlice({
	name: 'assets',
	initialState,
	reducers: {
		loading: (state) => {
			state.loading = true;
		},
		loaded: (state) => {
			state.loading = false;
		},
		assetLoading: (state) => {
			state.assetLoading = true;
		},
		assetLoaded: (state) => {
			state.assetLoading = false;
		},
		archivationLoading: (state) => {
			state.archivationLoading = true;
		},
		archivationLoaded: (state) => {
			state.archivationLoading = false;
		},
		commentsLoading: (state) => {
			state.commentsLoading = true;
		},
		commentsLoaded: (state) => {
			state.commentsLoading = false;
		},
		setAssets: (state, { payload }: PayloadAction<IAsset[]>) => {
			state.items = payload;
		},
		setArchivedAssets: (state, { payload }: PayloadAction<IAsset[]>) => {
			state.archivedItems = payload;
		},
	},
});

export const getAssets =
	(ownerId?: string, withLoading?: boolean) =>
	async (dispatch: Dispatch<any>, getState: () => RootState) => {
		if (withLoading) dispatch(loading());

		try {
			const { info, isAdmin } = getState().user;
			const companyId = getState().company?.info?.id;
			const companyUsers = getState().companyUsers.items;

			if (!companyId) return dispatch(setAssets([]));

			const assets = await assetsApi.getAssets(companyId);

			const transformedAssets = assets.map((asset) => {
				let ownerObj: ICompanyUser | undefined | null = null;

				if (isAdmin) {
					ownerObj = companyUsers?.find(
						(user: ICompanyUser) => user.id === asset.ownerId?.toString(),
					);
				} else {
					if (info && asset.ownerId?.toString() === info?.id) {
						ownerObj = {
							id: info.id,
							firstName: info.firstName,
							lastName: info.lastName,
							email: info.email,
							fullName: `${info.firstName} ${info.lastName}`,
						} as ICompanyUser;
					}
				}

				return {
					...asset,
					id: asset.id.toString(),
					owner: ownerObj || null,
				};
			});

			if (ownerId) {
				const filteredAssets = transformedAssets.filter(
					(asset) => asset.owner?.id === ownerId,
				);
				await dispatch(setAssets(sortByNameAsc(filteredAssets)));
				return;
			}

			await dispatch(setAssets(sortByNameAsc(transformedAssets)));
		} catch (error: any) {
			dispatch(setAssets([]));
		} finally {
			dispatch(loaded());
		}
	};

export const getArchivedAssets =
	(ownerId?: string) => async (dispatch: Dispatch<any>, getState: () => RootState) => {
		try {
			const { info, isAdmin } = getState().user;
			const companyId = getState().company?.info?.id;
			const companyUsers = getState().companyUsers.items;

			if (!companyId) return dispatch(setArchivedAssets([]));

			const assets = await assetsApi.getArchivedAssets(companyId);

			const transformedAssets = assets.map((asset) => {
				let ownerObj: ICompanyUser | undefined | null = null;

				if (isAdmin) {
					ownerObj = companyUsers?.find(
						(user: ICompanyUser) => user.id === asset.ownerId?.toString(),
					);
				} else {
					if (info && asset.ownerId?.toString() === info?.id) {
						ownerObj = {
							id: info.id,
							firstName: info.firstName,
							lastName: info.lastName,
							email: info.email,
							fullName: `${info.firstName} ${info.lastName}`,
						} as ICompanyUser;
					}
				}

				return {
					...asset,
					id: asset.id.toString(),
					owner: ownerObj,
				};
			});

			if (ownerId) {
				const filteredAssets = transformedAssets.filter(
					(asset) => asset.owner?.id === ownerId,
				);
				await dispatch(setArchivedAssets(sortByNameAsc(filteredAssets)));
				return;
			}

			await dispatch(setArchivedAssets(sortByNameAsc(transformedAssets)));
		} catch (error: any) {
			dispatch(setArchivedAssets([]));
		}
	};

export const getAssetById =
	(id: string) => async (dispatch: Dispatch<any>, getState: () => RootState) => {
		dispatch(assetLoading());

		try {
			const { info, isAdmin } = getState().user;
			const { items: companyUsers } = getState().companyUsers;
			const { items: controlItems } = getState().controls;

			const asset = await assetsApi.getAssetById(id);

			let userObj: ICompanyUser | undefined | null = null;

			if (isAdmin) {
				userObj = companyUsers?.find(
					(user: ICompanyUser) => user.id === asset.ownerId?.toString(),
				);
			} else {
				if (info && asset.ownerId?.toString() === info?.id) {
					userObj = {
						id: info.id,
						firstName: info.firstName,
						lastName: info.lastName,
						email: info.email,
						fullName: `${info.firstName} ${info.lastName}`,
					} as ICompanyUser;
				}
			}

			const transformedControls = controlItems
				?.filter((control) => asset.companyControlIds?.includes(Number(control.id)))
				.map(
					(control) =>
						({
							id: control.id,
							displayId: control.controlId,
							name: control.name,
						}) as IIdName,
				);

			return {
				...asset,
				id,
				companyControls: transformedControls || [],
				owner: userObj || null,
				tags: asset.tags?.map((t) => ({
					id: t.id?.toString(),
					name: t.name,
					color: t.color,
				})),
			};
		} catch (error: any) {
			throw new CommonResponseError('Error while getting asset.');
		} finally {
			dispatch(assetLoaded());
		}
	};

export const updateAsset =
	(assetId: string, companyId: string, data: IUpdateAsset, ownerId?: string) =>
	async (dispatch: Dispatch<any>) => {
		dispatch(assetLoading());

		try {
			await assetsApi.updateAsset(assetId, companyId, data);

			await dispatch(getAssets(ownerId));
		} catch (error: any) {
			throw new CommonResponseError('Error while updating asset.');
		} finally {
			dispatch(assetLoaded());
		}
	};

export const reapproveAsset =
	(assetId: string, companyId: string, data: IUpdateAsset, ownerId?: string) =>
	async (dispatch: Dispatch<any>) => {
		dispatch(assetLoading());

		try {
			await assetsApi.reapproveAsset(assetId, companyId, data);

			await dispatch(getAssets(ownerId));
		} catch (error: any) {
			throw new CommonResponseError('Error while updating asset.');
		} finally {
			dispatch(assetLoaded());
		}
	};

export const assignAssets =
	(ownerId: string | null, assets: string[]) => async (dispatch: Dispatch<any>) => {
		try {
			await assetsApi.assignAssets(ownerId, assets);
			await dispatch(getAssets());
		} catch (error: any) {
			throw new CommonResponseError('Error while assigning assets.');
		}
	};

export const archiveAssets = (assets: string[]) => async (dispatch: Dispatch<any>) => {
	dispatch(archivationLoading());

	try {
		await assetsApi.archiveAssets(assets);
		await dispatch(getAssets());
		await dispatch(getArchivedAssets());
	} catch (error: any) {
		throw new CommonResponseError('Error while archiving assets.');
	} finally {
		dispatch(archivationLoaded());
	}
};

export const createAsset =
	(companyId: string, data: ICreateAsset) => async (dispatch: Dispatch<any>) => {
		dispatch(assetLoading());

		try {
			const createdAsset = await assetsApi.createAsset(companyId, data);

			await dispatch(getAssets());

			return createdAsset?.assetId?.toString();
		} catch (error: any) {
			throw new CommonResponseError('Error while creating asset.');
		} finally {
			dispatch(assetLoaded());
		}
	};

export const addCommentToAsset =
	(newComment: ICreateComment) => async (dispatch: Dispatch<any>) => {
		dispatch(commentsLoading());

		try {
			await assetsApi.addComment(newComment);
		} catch (error: any) {
			throw new CommonResponseError('Error while adding comment.');
		} finally {
			dispatch(commentsLoaded());
		}
	};

export const {
	loading,
	loaded,
	assetLoaded,
	assetLoading,
	commentsLoading,
	commentsLoaded,
	setAssets,
	setArchivedAssets,
	archivationLoading,
	archivationLoaded,
} = assetsSlice.actions;
export default assetsSlice.reducer;
