import classNames from 'classnames';
import { FC, MutableRefObject, RefObject, useCallback, useEffect, useRef } from 'react';
import styles from './Verify.module.scss';

const KEYBOARDS = {
	backspace: 8,
	arrowLeft: 37,
	arrowRight: 39,
	tab: 9,
	enter: 13,
};

interface IVerifyInputsProps {
	error: string;
	handleEnter: () => void;
	setAuthenticatorCode: (elRefs: MutableRefObject<RefObject<HTMLInputElement>[]>) => void;
	setSubmitDisabled: React.Dispatch<React.SetStateAction<boolean>>;
	setError: React.Dispatch<React.SetStateAction<string>>;
}

export const VerifyInputs: FC<IVerifyInputsProps> = ({
	error,
	handleEnter,
	setAuthenticatorCode,
	setSubmitDisabled,
	setError,
}) => {
	const elRefs = useRef([
		useRef<HTMLInputElement>(null),
		useRef<HTMLInputElement>(null),
		useRef<HTMLInputElement>(null),
		useRef<HTMLInputElement>(null),
		useRef<HTMLInputElement>(null),
		useRef<HTMLInputElement>(null),
	]);

	const submitIsDisabled = useCallback(() => {
		return elRefs.current.some((input) => !input.current!.value);
	}, [elRefs]);

	const handleInput = useCallback(
		(e: any) => {
			const input = e.target;
			const nextInput = input.nextElementSibling;
			if (nextInput && input.value) {
				nextInput.focus();
				if (nextInput.value) {
					nextInput.select();
				}
			}
			setSubmitDisabled(submitIsDisabled());
			setAuthenticatorCode(elRefs);
			setError('');
		},
		[setAuthenticatorCode, setSubmitDisabled, submitIsDisabled, setError],
	);

	const handlePaste = useCallback(
		(e: any) => {
			e.preventDefault();
			const paste = e.clipboardData.getData('text').replace(/\D/g, '');

			elRefs.current.forEach((input, i) => {
				input.current!.value = paste[i] || '';
			});

			setSubmitDisabled(submitIsDisabled());
			setAuthenticatorCode(elRefs);
		},
		[setAuthenticatorCode, setSubmitDisabled, submitIsDisabled],
	);

	const handleBackspace = useCallback(
		(e: any) => {
			const input = e.target;
			if (input.value) {
				input.value = '';
				return;
			}
			if (input.previousElementSibling) input.previousElementSibling.focus();

			setSubmitDisabled(submitIsDisabled());
			setAuthenticatorCode(elRefs);
		},
		[setAuthenticatorCode, setSubmitDisabled, submitIsDisabled],
	);

	const handleArrowLeft = useCallback((e: any) => {
		const previousInput = e.target.previousElementSibling;
		if (!previousInput) return;
		previousInput.focus();
	}, []);

	const handleArrowRight = useCallback((e: any) => {
		const nextInput = e.target.nextElementSibling;
		if (!nextInput) return;
		nextInput.focus();
	}, []);

	useEffect(() => {
		setTimeout(() => {
			elRefs.current[0].current!.addEventListener('paste', handlePaste);

			elRefs.current.forEach((input) => {
				input.current!.addEventListener('focus', (e) => {
					setTimeout(() => {
						// @ts-ignore
						e.target!.select();
					}, 0);
				});

				input.current!.addEventListener('input', handleInput);

				input.current!.addEventListener('keydown', (e) => {
					if (
						!isNaN(Number(e.key)) ||
						Object.values(KEYBOARDS).includes(e.keyCode) ||
						e.code === 'KeyV'
					) {
						switch (e.keyCode) {
							case KEYBOARDS.backspace:
								handleBackspace(e);
								break;
							case KEYBOARDS.arrowLeft:
								handleArrowLeft(e);
								break;
							case KEYBOARDS.arrowRight:
								handleArrowRight(e);
								break;
							case KEYBOARDS.enter:
								handleEnter();
								break;
							default:
						}
					} else {
						e.preventDefault();
						return;
					}
				});
			});
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [elRefs.current]);

	return (
		<div className={styles['inputs-wrap']}>
			{elRefs.current &&
				elRefs.current.map((_ref, i) => (
					<input
						key={i}
						autoFocus={i === 0}
						type="tel"
						ref={elRefs.current[i]}
						maxLength={1}
						pattern="[0-9]"
						className={classNames(styles.input, error ? styles.inputError : '')}
					/>
				))}
		</div>
	);
};
