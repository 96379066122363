import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Table, ReadMore, TableSkeleton, ActionsBlock, Scrollbar } from '../../../primitives';
import { getPendingAccessRequestsColumns } from './utils';
import styles from './PendingAccessRequests.module.scss';
import classNames from 'classnames';
import IPendingAccessRequests from './IPendingAccessRequests';
import { useNavigate } from 'react-router-dom';
import { AppUrls } from '../../../../utils/helpers/constants/app-routes';
import { handleWithTryCatch } from '../../../../utils/helpers/errors';
import { useAppDispatch } from '../../../../services/store';
import { useTrustHub } from '../../../../utils/helpers/hooks';
import { getPendingTrustHubRequestAccessItems, ITrustHubRequestAccessDto } from '../../../../services/store/slices/trust-hub.slice';
import { ISortProps } from '../../../../utils/types';
import { processTableData } from '../../../../utils/helpers/common';

const PendingAccessRequests: FC<IPendingAccessRequests> = ({ className }) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { trustHubRequestAccessItems, loading } = useTrustHub();
	const [processedData, setProcessedData] = useState(trustHubRequestAccessItems);
	const [isProcessing, setIsProcessing] = useState(true);
	const [currentSort, setCurrentSort] = useState<ISortProps>({ property: '', direction: '' });
	const onSortChange = useCallback((newSort: ISortProps) => setCurrentSort(newSort), []);

	useEffect(() => {
		if (!trustHubRequestAccessItems) return;
		setIsProcessing(true);

		const processedData: ITrustHubRequestAccessDto[] = processTableData(trustHubRequestAccessItems, {}, currentSort);

		setProcessedData(processedData);
		setIsProcessing(false);
	}, [currentSort, trustHubRequestAccessItems]);

	const renderEmptyState = () => {
		return (
			<div className={styles['empty-message']}>
				<span className={styles.message}>No requests to display yet</span>
			</div>
		);
	};

	const renderContent = useMemo(() => {
		if (!trustHubRequestAccessItems?.length) return renderEmptyState();

		return (
			<Scrollbar small className="max-h-[232px]">
				<Table
					tableKey="recent-tags"
					data={processedData || []}
					columns={getPendingAccessRequestsColumns()}
					options={{
						rowClassName: styles.row,
						emptyResultMessage: 'No pending requests to display yet.',
						emptyResultMessageType: 'common',
						extraSmall: true,
						noScroll: true,
						onSortChange
					}}
				/>
			</Scrollbar>
		);
	}, [processedData, trustHubRequestAccessItems, onSortChange]);

	useEffect(() => {
		handleWithTryCatch(async () => {
			await dispatch(getPendingTrustHubRequestAccessItems(true));
		});
	}, [dispatch]);

	return (
		<div className={classNames(styles['pending-access'], className)}>
			<ActionsBlock className='!mt-0'>
				<h3>Pending Access Requests</h3>

				{!loading && (
					<ReadMore
						text="See all Requests"
						onClickHandler={() => navigate(AppUrls.trustHubAccessRequests)}
					/>
				)}
			</ActionsBlock>
			{isProcessing || loading ? (
				<TableSkeleton extraSmall rowsNumber={4} columns={getPendingAccessRequestsColumns()} />
			) : (
				renderContent
			)}
		</div>
	);
};

export default PendingAccessRequests;
