import { Dispatch } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommonResponseError } from '../../../utils/helpers/errors/CommonResponseError';
import {
	IChangeHistoryEntry,
	IComment,
	IIdName,
	ICapturedValues,
	ICreateComment,
} from '../../../utils/types';
import { RootState } from '../../../services/store';
import controlsApi from '../../api/controls.api';
import { CommonStatuses } from '../../../utils/helpers/constants';
import { ICompanyUser } from './company-users.slice';
import { sortControlObjByControlIdAsc } from '../../../utils/helpers/common';
import { ITag, ITagBrief } from './tags.slice';

export interface IControlsSlice {
	loading?: boolean;
	controlLoading?: boolean;
	updateLoading?: boolean;
	commentsLoading?: boolean;
	error?: string | null;
}

export interface IControlBasic {
	id: number;
	controlId: string;
	controlName?: string;
	controlStatement?: string;
	guidance?: string;
	status?: string;
	ownerId?: number | null;
	tags?: Partial<ITag>[];
	frameworkName?: string[];
	history?: IChangeHistoryEntry[];
	capturedValues?: ICapturedValues;
	comments?: IComment[];
	trustHubDefaultStatement?: string;
	enhancedFamilyName?: string;
}

export interface IControl
	extends Omit<IControlBasic, 'controlName' | 'controlStatement' | 'owner' | 'id' | 'tags'> {
	id: string;
	name?: string;
	statement?: string;
	owner?: ICompanyUser | null;
	framework?: IIdName;
	tags?: ITagBrief[];
}

export interface IControls extends IControlsSlice {
	items: IControl[] | null;
}

export interface IUpdateControl {
	id?: string;
	name?: string;
	statement?: string;
	status?: string;
	ownerId?: string;
	tags?: ITagBrief[];
	capturedValues?: ICapturedValues;
}

const initialState: IControls = {
	loading: false,
	updateLoading: false,
	commentsLoading: false,
	controlLoading: false,
	items: null,
	error: null,
};

export const controlsSlice = createSlice({
	name: 'controls',
	initialState,
	reducers: {
		loading: (state) => {
			state.loading = true;
		},
		loaded: (state) => {
			state.loading = false;
		},
		controlLoading: (state) => {
			state.controlLoading = true;
		},
		controlLoaded: (state) => {
			state.controlLoading = false;
		},
		updateLoading: (state) => {
			state.updateLoading = true;
		},
		updateLoaded: (state) => {
			state.updateLoading = false;
		},
		commentsLoading: (state) => {
			state.commentsLoading = true;
		},
		commentsLoaded: (state) => {
			state.commentsLoading = false;
		},
		setControlsError: (state) => {
			state.error = 'Error while getting company controls';
		},
		setControls: (state, { payload }: PayloadAction<IControl[]>) => {
			state.items = payload;
			state.error = null;
		},
	},
});

export const getControls =
	(withLoading?: boolean) => async (dispatch: Dispatch<any>, getState: () => RootState) => {
		if (withLoading) dispatch(loading());

		try {
			const controlsData = await controlsApi.getControls();

			const { info, isAdmin } = getState().user;
			const primaryFramework = getState().company.primaryFramework;
			const companyUsers = getState().companyUsers.items;

			let itemsToDisplay: IControl[] = controlsData?.map((control: IControlBasic) => {
				const {
					id,
					controlId,
					controlName,
					controlStatement,
					status,
					ownerId,
					capturedValues,
					tags,
				} = control;

				let ownerObj: ICompanyUser | undefined | null = null;

				if (isAdmin) {
					ownerObj = ownerId
						? companyUsers?.find((user) => user.id === ownerId.toString())
						: null;
				} else {
					if (info && ownerId?.toString() === info?.id) {
						ownerObj = {
							id: info.id,
							firstName: info.firstName,
							lastName: info.lastName,
							email: info.email,
							fullName: `${info.firstName} ${info.lastName}`,
						} as ICompanyUser;
					}
				}

				return {
					id: id.toString(),
					controlId,
					name: controlName,
					statement: controlStatement,
					status:
						CommonStatuses[status as keyof typeof CommonStatuses] ||
						CommonStatuses.draft,
					framework: {
						id: primaryFramework?.id,
						name: primaryFramework?.name,
					} as IIdName,
					owner: ownerObj,
					capturedValues,
					tags: tags?.map((t) => ({
						id: t.id?.toString(),
						name: t.name,
						color: t.color,
					})),
				} as IControl;
			});

			await dispatch(setControls(sortControlObjByControlIdAsc(itemsToDisplay)));
		} catch (error: any) {
			dispatch(setControls([]));
			dispatch(setControlsError());
		} finally {
			dispatch(loaded());
		}
	};

export const getPublicControls =
	(companySlug: string, selectedFramework?: string, withLoading?: boolean) =>
	async (dispatch: Dispatch<any>, getState: () => RootState) => {
		if (withLoading) dispatch(loading());

		try {
			const controlsData = await controlsApi.getPublicCompanyControls(
				companySlug,
				selectedFramework,
			);

			const { info, isAdmin } = getState().user;
			const primaryFramework = getState().company.primaryFramework;
			const companyUsers = getState().companyUsers.items;

			let itemsToDisplay: IControl[] = controlsData?.map((control: IControlBasic) => {
				const {
					id,
					controlId,
					controlName,
					controlStatement,
					status,
					ownerId,
					capturedValues,
					tags,
					trustHubDefaultStatement,
					enhancedFamilyName,
				} = control;

				let ownerObj: ICompanyUser | undefined | null = null;

				if (isAdmin) {
					ownerObj = ownerId
						? companyUsers?.find((user) => user.id === ownerId.toString())
						: null;
				} else {
					if (info && ownerId?.toString() === info?.id) {
						ownerObj = {
							id: info.id,
							firstName: info.firstName,
							lastName: info.lastName,
							email: info.email,
							fullName: `${info.firstName} ${info.lastName}`,
						} as ICompanyUser;
					}
				}

				return {
					id: id.toString(),
					controlId,
					name: controlName,
					statement: controlStatement,
					status:
						CommonStatuses[status as keyof typeof CommonStatuses] ||
						CommonStatuses.draft,
					framework: {
						id: primaryFramework?.id,
						name: primaryFramework?.name,
					} as IIdName,
					owner: ownerObj,
					capturedValues,
					tags: tags?.map((t) => ({
						id: t.id?.toString(),
						name: t.name,
						color: t.color,
					})),
					trustHubDefaultStatement,
					enhancedFamilyName,
				} as IControl;
			});

			await dispatch(setControls(sortControlObjByControlIdAsc(itemsToDisplay)));
		} catch (error: any) {
			console.error('Error while getting company controls', error);
			dispatch(setControls([]));
			dispatch(setControlsError());
		} finally {
			dispatch(loaded());
		}
	};

export const getControlById =
	(currentControlId: string) => async (dispatch: Dispatch<any>, getState: () => RootState) => {
		dispatch(controlLoading());

		try {
			const control = await controlsApi.getControlById(currentControlId);

			const { info, isAdmin } = getState().user;
			const primaryFramework = getState().company.primaryFramework;
			const companyUsers = getState().companyUsers.items;

			const {
				id,
				controlId,
				controlName,
				controlStatement,
				guidance,
				status,
				ownerId,
				history,
				capturedValues,
				comments,
				tags,
			} = control;

			let userObj: ICompanyUser | undefined | null = null;

			if (isAdmin) {
				userObj = companyUsers?.find(
					(user: ICompanyUser) => user.id === ownerId?.toString(),
				);
			} else {
				if (info && ownerId?.toString() === info?.id) {
					userObj = {
						id: info.id,
						firstName: info.firstName,
						lastName: info.lastName,
						email: info.email,
						fullName: `${info.firstName} ${info.lastName}`,
					} as ICompanyUser;
				}
			}

			return {
				id: id.toString(),
				controlId,
				name: controlName,
				statement: controlStatement,
				guidance,
				status:
					CommonStatuses[status as keyof typeof CommonStatuses] || CommonStatuses.draft,
				framework: {
					id: primaryFramework?.id,
					name: primaryFramework?.name,
				} as IIdName,
				owner: userObj || null,
				history,
				comments,
				capturedValues,
				tags: tags?.map((t) => ({
					id: t.id?.toString(),
					name: t.name,
					color: t.color,
				})),
			} as IControl;
		} catch (error: any) {
			throw new CommonResponseError('Error while getting control.');
		} finally {
			dispatch(controlLoaded());
		}
	};

export const updateControl =
	(id: string, data: IUpdateControl) => async (dispatch: Dispatch<any>) => {
		dispatch(updateLoading());

		try {
			await controlsApi.updateControl(id, {
				ownerId: data.ownerId,
				controlStatement: data.statement,
				status: data.status,
				capturedValues: data.capturedValues || {},
				tags: data.tags || [],
			} as IUpdateControl);

			await dispatch(getControls());
		} catch (error: any) {
			throw new CommonResponseError('Error while updating control.');
		} finally {
			dispatch(updateLoaded());
		}
	};

export const reapproveControl =
	(id: string, data: IUpdateControl) => async (dispatch: Dispatch<any>) => {
		dispatch(updateLoading());

		try {
			await controlsApi.reapproveControl(id, {
				ownerId: data.ownerId,
				controlStatement: data.statement,
				status: data.status,
				capturedValues: data.capturedValues || {},
				tags: data.tags || [],
			} as IUpdateControl);

			await dispatch(getControls());
		} catch (error: any) {
			throw new CommonResponseError('Error while updating control.');
		} finally {
			dispatch(updateLoaded());
		}
	};

export const assignControls =
	(ownerId: string, controls: string[]) => async (dispatch: Dispatch<any>) => {
		try {
			await controlsApi.assignControls(ownerId, controls);
			await dispatch(getControls());
		} catch (error: any) {
			throw new CommonResponseError('Error while assigning controls.');
		}
	};

export const addCommentToControl =
	(newComment: ICreateComment) => async (dispatch: Dispatch<any>) => {
		dispatch(commentsLoading());

		try {
			await controlsApi.addComment(newComment);
			await dispatch(getControls(false));
		} catch (error: any) {
			throw new CommonResponseError('Error while adding comment.');
		} finally {
			dispatch(commentsLoaded());
		}
	};

export const getControlsByType =
	(type: string) => async (dispatch: Dispatch<any>, getState: () => RootState) => {
		try {
			const { info: companyInfo } = getState().company;
			const controlItems = getState().controls.items;

			const controlIds = await controlsApi.getControlsByType(type, companyInfo?.id || '');
			const controlObjs = controlItems?.filter((control) =>
				controlIds?.find((controlId) => control.id === controlId.toString()),
			);

			return controlObjs || [];
		} catch (error: any) {
			throw new CommonResponseError('Error while getting controls for type.');
		}
	};

export const {
	loading,
	loaded,
	controlLoading,
	controlLoaded,
	updateLoading,
	updateLoaded,
	commentsLoading,
	commentsLoaded,
	setControls,
	setControlsError,
} = controlsSlice.actions;
export default controlsSlice.reducer;
